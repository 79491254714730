import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { ApplicationComponent } from './application.component';
import { SharedApplicationModule } from './shared/shared-application.module';
import { ApplicationRoutingModule } from './application-routing.module';
import { applicationKey } from './index';
import { reducer } from './application.reducer';

@NgModule({
  declarations: [
    ApplicationComponent
  ],
  imports: [
    // Vendor modules
    StoreModule.forFeature(applicationKey, reducer),

    // App modules
    SharedApplicationModule,
    ApplicationRoutingModule,
  ]
})
export class ApplicationModule {

}
