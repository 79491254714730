<div class="card my-3">
  <div class="card-header d-flex">
    <h5 class="flex-grow-1">
      Přidat zákazníka
    </h5>
    <a (click)="closeModal()" class="cursor-pointer">
      <i class="fal fa-times fa-2x"></i>
    </a>
  </div>

  <form #form="ngForm" (submit)="onSubmit()">

    <div class="overflow-auto">
      <app-customer-form [customer]="customer"></app-customer-form>
    </div>

    <app-error *ngIf="savingAction.isFinishedWithFailure" [data]="savingAction.error" class="mx-4"></app-error>

    <div class="form-group form-group-sm buttons">
      <button [disabled]="form.invalid || savingAction.isWorking" type="submit" class="btn btn-primary">
        <ng-container *ngIf="!savingAction.isWorking">
          Přidat zákazníka
        </ng-container>
        <ng-container *ngIf="savingAction.isWorking">
          <div class="spinner-border spinner-border-sm"></div>
          Ukládám...
        </ng-container>
      </button>
    </div>

  </form>
</div>
