import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AppState } from '../../../../app.state';
import { getAuthState } from '../../../../auth';
import { AuthState } from '../../../../auth/auth.state';
import { ApplicationState } from '../../../application.state';
import { getApplicationState } from '../../../index';
import { AuthService } from '../../../../auth/services/auth.service';
import { Action } from '../../../../core/utils/action';
import { Router } from '@angular/router';
import { FlashMessagesService } from '../flash-messages/flash-messages.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {

  public authState: AuthState;
  public applicationState: ApplicationState;
  public menuLinks: { title: string; routerLink: string, icon?: string, requireAdmin?: boolean }[] = [];
  public logoutAction = new Action();

  private authStoreSubscription: Subscription;
  private applicationStateSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
  ) {
    this.menuLinks = this.createMenu();
  }

  public ngOnInit() {
    this.authStoreSubscription = this.store.select(getAuthState).subscribe(authState => this.authState = authState);
    this.applicationStateSubscription = this.store
      .select(getApplicationState)
      .subscribe(applicationState => this.applicationState = applicationState);
  }

  public ngOnDestroy() {
    if (this.authStoreSubscription) {
      this.authStoreSubscription.unsubscribe();
    }

    if (this.applicationStateSubscription) {
      this.applicationStateSubscription.unsubscribe();
    }
  }

  private createMenu() {
    return [
      { title: 'Přehled', routerLink: 'dashboard', icon: 'fas fa-home' },
      { title: 'Pracoviště', routerLink: 'workplaces', requireAdmin: true },
      { title: 'Zaměstnanci', routerLink: 'employees', requireAdmin: true },
      { title: 'Procedury', routerLink: 'procedures', requireAdmin: true },
      { title: 'Zákazníci', routerLink: 'customers' },
      { title: 'Dostupnost', routerLink: 'allocations' },
    ];
  }

  public async logout() {
    try {
      this.logoutAction.start();
      await this.authService.logout().toPromise();
      this.logoutAction.setSuccess();
      this.flashMessagesService.addSuccess('Byl jsi úspěšně odhlášen');
      await this.router.navigateByUrl('/auth/login');
    } catch (e) {
      this.logoutAction.setError(e);
    }
  }

}
