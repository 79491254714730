import { animate, state, style, transition, trigger } from '@angular/animations';

export const toggleVisibility = trigger('toggleVisibility', [
  state('void', style({
    opacity: 0,
  })),
  state('hidden', style({
    opacity: 0,
  })),
  state('visible', style({
    opacity: 1,
  })),
  transition('void => visible', [animate('300ms 300ms ease-out')]),
  transition('* => void', [animate('300ms 300ms ease-out')]),
]);

export const slidePanelToView = trigger('slidePanelToView', [
  state('void', style({
    transform: 'translateX(-100%)',
  })),
  state('hidden', style({
    transform: 'translateX(-100%)',
  })),
  state('visible', style({
    transform: 'translateX(0)',
  })),
  transition('void => visible', [animate('700ms ease')]),
  transition('* => void', [animate('700ms ease')]),
]);
