import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.state';
import { AuthService } from './auth.service';
import { setProfile } from '../auth.actions';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
  ) {
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const { user } = await this.authService.getProfile().toPromise();
      this.store.dispatch(setProfile({ user }));

      return !!user;
    } catch (e) {
      await this.router.navigate(['/auth/login'], { queryParams: { return: state.url } });
      return false;
    }
  }

}
