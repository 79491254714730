import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { AuthComponent } from './auth.component';
import { AuthEffects } from './auth.effects';
import { reducer } from './auth.reducer';
import { authKey } from './index';
import { RolePipe } from './pipes/role.pipe';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IsAdminDirective } from './directives/is-admin.directive';

@NgModule({
  declarations: [
    LoginComponent,
    AuthComponent,
    RolePipe,
    IsAdminDirective,
  ],
  imports: [
    // Vendor modules
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(authKey, reducer),
    FormsModule,
    CommonModule,

    // App modules
    AuthRoutingModule,
    SharedModule,
  ],
  exports: [
    RolePipe,
    IsAdminDirective,
  ],
})
export class AuthModule {
}
