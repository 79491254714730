<ng-container *ngIf="isSimple && error">
  <div class="alert alert-danger">
    <strong>Nastala systémová chyba. Pokud problém přetvrá, kontaktujte prosím administrátora systému.</strong>
    <br>
    <br>
    Popis chyby: {{ error.message }}

    <br>

    <pre class="small text-muted mt-3">{{ error.stack }}</pre>
  </div>
</ng-container>

<ng-container *ngIf="!isSimple && error">
  <div class="alert alert-danger">
    <div class="font-weight-bold">
      {{ message }}
    </div>

    <div [innerHTML]="error"></div>
  </div>
</ng-container>

<div *ngIf="internalErrorOccurred" class="alert alert-danger">
  <strong>Chyba 500 - systémová chyba</strong>: Nastala systémová chyba, kontaktuje prosím systémového administrátora, děkujeme za pochopení.
</div>

<div *ngIf="pageNotFound" class="alert alert-danger">
  <strong>Chyba 404 - stránka nenalezena</strong>: Adresa na kterou se snažíte přistoupit bohužel neexistuje.<br>
  <br>
  <span class="text-muted small"><strong>Url</strong>: {{ url }}</span>
</div>
