import { Workplace } from '../../application/workplaces';

export interface User {
  id: number;
  fullName: string;
  email: string;
  role: Role;
  createdAt: string;
  color: string;
  createdBy?: User;
  password?: string; // for user creation
  workplaces?: Workplace[];
  profilePhoto?: string;
  profilePhotoSmall?: string;
}

export enum Role {
  'Admin' = 'admin',
  'Employee' = 'employee',
}

export const ROLES = [
  { id: Role.Employee, title: 'Zaměstnanec' },
  { id: Role.Admin, title: 'Administrátor' },
];
