import { clone } from 'lodash-es';
import { HttpErrorResponse } from '@angular/common/http';

export class Action<T, U extends Error = Error | HttpErrorResponse> {

  public result: T;
  public error: U;
  public statusText: string;
  public isFinished = false;

  public constructor(
    private readonly copyOnChange = false
  ) {
  }

  public status = Status.INITIAL;

  public start() {
    const instance = this.getInstance();
    instance.status = Status.WORKING;
    instance.statusText = '';
    instance.result = null;
    instance.isFinished = false;

    return instance;
  }

  public setSuccess(result: T = null) {
    const instance = this.getInstance();
    instance.result = result;
    instance.statusText = '';
    instance.error = null;
    instance.isFinished = true;
    instance.status = Status.SUCCEEDED;

    return instance;
  }

  public setError(error: U) {
    const instance = this.getInstance();
    instance.status = Status.FAILED;
    instance.error = error;
    instance.result = null;
    instance.statusText = '';
    instance.isFinished = true;

    return instance;
  }

  public get isWorking() {
    return this.status === Status.WORKING;
  }

  public get isFinishedWithSuccess() {
    return this.isFinished && this.status === Status.SUCCEEDED;
  }

  public get isFinishedWithFailure() {
    return this.isFinished && this.status === Status.FAILED;
  }

  /**
   * Support for semi-states - you can group several smaller actions into one. Then you need to tell subscriber what is going on....
   */
  public setStatusText(text: string) {
    const instance = this.getInstance();
    instance.statusText = text;

    return;
  }

  public toString() {
    return this.status;
  }

  public setInitial() {
    const instance = this.getInstance();
    instance.status = Status.INITIAL;
    instance.result = null;
    instance.error = null;

    return instance;
  }

  public setResult(result: T) {
    const instance = this.getInstance();
    instance.result = result;

    return instance;
  }

  /**
   * Support for immutable states
   */
  private getInstance() {
    return this.copyOnChange ? clone(this) : this;
  }

}


export enum Status {
  INITIAL = 'INITIAL',
  WORKING = 'WORKING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}
