import { Component, OnInit } from '@angular/core';
import { slidePanelToView } from './components';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [
    slidePanelToView
  ]
})
export class AuthComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
