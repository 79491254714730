import { Component, Input } from '@angular/core';
import { FlashMessage } from './flash-message';
import { FlashMessagesService } from './flash-messages.service';

@Component({
  selector: 'app-flash-messages',
  templateUrl: './flash-messages.component.html',
  styleUrls: ['./flash-messages.component.scss'],
})
export class FlashMessagesComponent {

  messages: FlashMessage[] = [];

  constructor(
    private flashMessageService: FlashMessagesService
  ) {
    this.messages = flashMessageService.messages;
  }

  public close(index: number) {
    this.messages.splice(index, 1);
  }

  public removeById(id: string) {
    this.messages.splice(this.messages.findIndex(i => i.id === id), 1);
  }

  public onClick(message: FlashMessage) {
    if (typeof message.onClick === 'function') {
      message.onClick(message);
    }
  }
}
