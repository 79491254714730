import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Action } from '../../../../core/utils/action';
import { CustomersService } from '../../customers.service';
import { FlashMessagesService } from '../../../shared/components/flash-messages/flash-messages.service';
import { Customer } from '../../index';

@Component({
  selector: 'app-create-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.scss'],
})
export class CreateModalComponent implements OnInit {

  @HostBinding('class') className = 'modal-window';

  public customer = {} as Customer;
  public savingAction = new Action<null, HttpErrorResponse>();

  constructor(
    private customersService: CustomersService,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
  ) {
  }

  ngOnInit(): void {
  }

  async onSubmit() {
    try {
      this.savingAction.start();
      this.customer = await this.customersService.create(this.customer).toPromise();
      this.flashMessagesService.addSuccess('Zákazník byl vytvořen');
      this.savingAction.setSuccess();
      this.customersService.addCustomerSubject.next(this.customer);
      this.closeModal();
    } catch (e) {
      this.savingAction.setError(e);
    }
  }

  public closeModal() {
    this.router.navigate([{ outlets: { customers: null } }]);
  }

}
