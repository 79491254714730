<div class="d-flex min-vh-100">
  <div class="flex-grow-1">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <router-outlet name="modals"></router-outlet>
  </div>
</div>








