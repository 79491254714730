<div class="form-group form-group-sm">
  <label for="full-name" class="required">Celé jméno</label>
  <input name="fullName" required type="text" id="full-name" [(ngModel)]="customer.fullName" class="form-control max-width-300px">
</div>

<div class="form-group form-group-sm">
  <label for="phone" class="required">Telefon</label>
  <input name="phone" type="text" id="phone" required [(ngModel)]="customer.phone" class="form-control max-width-300px">
</div>

<div class="form-group form-group-sm">
  <label for="city">Město</label>
  <input name="city" type="text" id="city" [(ngModel)]="customer.city" class="form-control max-width-300px">
</div>

<div class="form-group form-group-sm">
  <label for="email">E-mail</label>
  <input name="email" type="text" id="email" [(ngModel)]="customer.email" class="form-control max-width-300px">
</div>

<div class="form-group form-group-sm border-bottom">
  <label for="company">Společnost</label>
  <input name="company" type="text" id="company" [(ngModel)]="customer.company" class="form-control max-width-300px">
</div>

<div *ngIf="customer.createdBy" class="form-group form-group-sm border-bottom align-items-center">
  <label for="created-by">Založil</label>
  <div id="created-by">
    <strong>{{ customer.createdBy.fullName }}</strong> <span class="text-muted"> v {{ customer.createdAt | date:"hh:mm, dd.MM.yyyy" }}</span>
  </div>
</div>
