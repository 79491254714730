import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { ApplicationModule } from './application/application.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { FlashMessagesModule } from './application/shared/components/flash-messages/flash-messages.module';
import { NgbDateCustomParserFormatter } from './shared/services/date-formater.service';

import localeCs from '@angular/common/locales/cs';
registerLocaleData(localeCs);


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // Vendor modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([]),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: () => adapterFactory(moment),
    }),

    // App Modules
    SharedModule,
    AuthModule,
    AppRoutingModule,
    ApplicationModule,
    FlashMessagesModule,
    NgbModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter,
    },
    {
      provide: LOCALE_ID,
      useValue: 'cs',
    },
  ],
  bootstrap: [AppComponent],
  exports: [
    AppComponent,
  ],
})
export class AppModule {

  constructor() {
    moment.locale('cs_CZ');
  }

}
