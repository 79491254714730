import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { getAuthState } from '../index';
import { AppState } from '../../app.state';
import { Role } from '../entities';

@Directive({
  selector: '[appIsAdmin]',
})
export class IsAdminDirective implements OnInit, OnDestroy {

  @Input() appIsAdmin;

  private stop$ = new Subject();
  private isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store: Store<AppState>,
  ) {
  }

  public ngOnInit() {
    //  We subscribe to the roles$ to know the roles the user has
    this.store.select(getAuthState).pipe(
      takeUntil(this.stop$),
    ).subscribe(authState => {
      if (!authState.user?.role) {
        // If he doesn't have any roles, we clear the viewContainerRef
        this.viewContainerRef.clear();
      }

      // If the user has the role needed to render this component we can add it
      if (this.appIsAdmin === true || authState?.user.role === Role.Admin) {
        // If it is already visible (which can happen if his roles changed) we do not need to add it a second time
        if (!this.isVisible) {
          // We update the `isVisible` property and add the templateRef to the view using the
          // 'createEmbeddedView' method of the viewContainerRef
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        // If the user does not have the role, we update the `isVisible` property and clear the contents of the viewContainerRef
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    });
  }

  // Clear the subscription on destroy
  public ngOnDestroy() {
    this.stop$.next();
  }

}
