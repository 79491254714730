import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
  ]
})
export class ApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
