import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Customer } from './index';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {

  public addCustomerSubject = new Subject<Customer>();

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  private getBaseUrl() {
    return environment.apiUrl + '/customers';
  }

  public list(params: { populate?: string } = {}) {
    return this.httpClient.get<Customer[]>(this.getBaseUrl(), { params });
  }

  public create(customer: Customer) {
    return this.httpClient.post<Customer>(this.getBaseUrl(), customer);
  }

  public delete(id: number) {
    return this.httpClient.delete(this.getBaseUrl() + '/' + id);
  }

  public get(id: string | number, params: { populate?: string } = {}) {
    return this.httpClient.get<Customer>(this.getBaseUrl() + '/' + id, { params });
  }

  public update(customer: Customer) {
    const payload = {
      fullName: customer.fullName || undefined,
      phone: customer.phone || undefined,
      email: customer.email || undefined,
      city: customer.city || undefined,
      company: customer.company || undefined,
    };

    return this.httpClient.patch<Customer>(this.getBaseUrl() + '/' + customer.id, payload);
  }

  public fulltextSearch(term: string) {
    return this.httpClient.get<Customer[]>(this.getBaseUrl() + '/fulltext-search/' + term);
  }

}
