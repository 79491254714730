import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { Customer } from '../../index';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CustomerFormComponent implements OnInit {

  @Input() customer: Customer;

  constructor() {
  }

  ngOnInit(): void {
  }

}
