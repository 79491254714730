<nav class="navbar navbar-expand-lg bg-white" ngbDropdown>

  <div class="navbar-brand mx-3">
    <img src="/assets/img/logo.png" height="30">
  </div>

  <a routerLink="/dashboard/event" queryParamsHandling="merge" class="ml-auto d-lg-none btn btn-sm btn-primary shadow-sm">
    <i class="fas fa-plus"></i>
    <span class="ml-1">Přidat událost</span>
  </a>

  <button class="navbar-toggler d-lg-none" type="button" ngbDropdownToggle>
    <i class="fas fa-bars"></i>
  </button>

  <div class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0 ml-4" style="font-size: 110%">
      <ng-container *ngFor="let link of menuLinks">
        <li *appIsAdmin="!link.requireAdmin" class="nav-item" routerLinkActive="active">
          <a [routerLink]="link.routerLink" class="nav-link">
            <i *ngIf="link.icon" class="icon {{ link.icon }}"></i>
            {{ link.title }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="ml-auto pr-3 d-none d-lg-block">
    <a [routerLink]="['', {outlets: {customers: 'create'}}]" class="btn btn-sm btn-primary shadow-sm mr-2">
      <i class="fas fa-plus"></i>
      <span class="d-none d-lg-inline-block ml-1">Přidat zákazníka</span>
    </a>

    <a routerLink="/dashboard/event" queryParamsHandling="merge" class="btn btn-sm btn-primary shadow-sm">
      <i class="fas fa-plus"></i>
      <span class="d-none d-lg-inline-block ml-1">Přidat událost</span>
    </a>

    <button (click)="logout()" class="btn btn-link">Odhlásit se</button>
  </div>

  <div class="d-lg-none" ngbDropdownMenu>
    <a *ngFor="let link of menuLinks" class="dropdown-item" routerLinkActive="active" [routerLink]="link.routerLink">
      <i *ngIf="link.icon" class="icon {{ link.icon }}"></i>
      {{ link.title }}
    </a>
    <button (click)="logout()" class="dropdown-item">Odhlásit se</button>
  </div>

</nav>
