import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { ApplicationComponent } from './application.component';
import { AuthGuardService } from '../auth/services/auth-guard.service';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: '', component: ApplicationComponent, canActivate: [AuthGuardService], children: [
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'workplaces', loadChildren: () => import('./workplaces/workplace.module').then(m => m.WorkplaceModule) },
      { path: 'employees', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
      {
        path: 'allocations',
        loadChildren: () => import('./allocations/allocations.module').then(m => m.AllocationsModule),
      },
      {
        path: 'procedures',
        loadChildren: () => import('./procedures/procedures.module').then(m => m.ProceduresModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ApplicationRoutingModule {
}
