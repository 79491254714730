import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateModalComponent } from './application/customers/pages/create-modal/create-modal.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule),
  },

  { path: 'create', outlet: 'customers', component: CreateModalComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
