import { Injectable } from '@angular/core';

import { FlashMessage } from './flash-message';
import { StringsService } from '../../../../core/services/strings.service';

const DEFAULT_DELAY = 5000;

@Injectable({
  providedIn: 'root',
})
export class FlashMessagesService {

  public messages: FlashMessage[] = [];

  constructor(
    private stringsService: StringsService,
  ) {
  }

  public addMessage(flashMessage: FlashMessage) {
    flashMessage.id = this.stringsService.generateUuid();
    this.messages.push(flashMessage);
    if (typeof flashMessage.delay === 'number' && flashMessage.delay >= 0) {
      setTimeout(() => {
        this.messages.splice(this.messages.findIndex(i => i.id === flashMessage.id), 1);
      }, flashMessage.delay);
    }
  }

  public addSuccess(htmlText: string, delay: number | boolean = DEFAULT_DELAY) {
    return this.addMessage({ htmlText, delay, className: 'success' });
  }

  public addDanger(htmlText: string, delay: number | boolean = DEFAULT_DELAY) {
    return this.addMessage({ htmlText, delay, className: 'danger' });
  }

}
