import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FormatHoursMinutesPipe } from './pipes/format-hours-minutes';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ValuesPipe } from './pipes/values.pipe';
import { MdPipe } from './pipes/md.pipe';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SharedModule as GlobalSharedModule } from '../../shared/shared.module';
import { ErrorComponent } from '../../shared/components/error-handler/error.component';
import { HttpErrorAlertComponent } from './components/http-error-alert/http-error-alert.component';
import { InlineEditComponent } from './components/inline-edit/inline-edit.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { AuthModule } from '../../auth/auth.module';
import { IsAdminDirective } from '../../auth/directives/is-admin.directive';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    FormatHoursMinutesPipe,
    SpinnerComponent,
    TruncatePipe,
    ValuesPipe,
    MdPipe,
    NavbarComponent,
    HttpErrorAlertComponent,
    InlineEditComponent,
    FileSizePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    GlobalSharedModule,
    AuthModule,
  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    SafeHtmlPipe,
    FormatHoursMinutesPipe,
    SpinnerComponent,
    TruncatePipe,
    ValuesPipe,
    MdPipe,
    NavbarComponent,
    ErrorComponent,
    HttpErrorAlertComponent,
    InlineEditComponent,
    FileSizePipe,
  ],
})
export class SharedApplicationModule {
}
