import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import { User } from '../entities';
import { setProfile } from '../auth.actions';
import { AppState } from '../../app.state';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
  ) {
  }

  public async refreshProfileInStore() {
    const { user } = await this.getProfile().toPromise();
    this.store.dispatch(setProfile({ user }));
  }

  public getProfile() {
    return this.httpClient.get<any>(environment.apiUrl + '/auth/profile', { observe: 'response' })
      .pipe(map((response: HttpResponse<User>) => ({ user: response.body })));
  }

  public login(params: Authenticate) {
    return this.httpClient.post<{ bearer: string, organisationId: string }>(environment.apiUrl + '/auth/sign/in', params);
  }

  public logout() {
    return this.httpClient.post(environment.apiUrl + '/auth/sign/out', {});
  }

  public changePassword(payload: ChangePasswordPayload) {
    return this.httpClient.post(environment.apiUrl + '/auth/profile/change-password', payload);
  }

}

export interface Authenticate {
  email: string;
  password: string;
}

export interface UpdateProfilePayload {
  fullName: string;
}

export interface ChangePasswordPayload {
  password: string;
}
