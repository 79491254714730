<div class="text-center mt-5 mb-3">
  <img src="/assets/img/logo.png" width="146">
</div>

<div class="card">

  <div class="card-header">
    <h3 class="mt-3">Přihlášení</h3>
  </div>

  <div class="card-body">
    <form #loginForm="ngForm" (ngSubmit)="onSubmit()">

      <div *ngIf="errorMessage" [ngSwitch]="errorMessage" class="alert alert-danger mb-3 text-center">
        <div *ngSwitchCase="'USER_NOT_FOUND'">Uživatel nenalezen</div>
        <div *ngSwitchCase="'PASSWORD_IS_WRONG'">Špatné heslo</div>
        <div *ngSwitchDefault>Nastala systémová chyba</div>
      </div>

      <div *ngIf="resetPassword" class="alert alert-success">
        Tvoje heslo bylo úspěšně nastaveno, nyní se můžeš přihlásit.
      </div>

      <div class="mb-3">
        <div class="input-group">
          <span class="input-group-prepend"><div class="input-group-text  border-right-0">@</div></span>
          <input
            [(ngModel)]="email"
            required
            #emailModel="ngModel"
            #emailField
            name="email"
            type="email" class="form-control"
            placeholder="E-mail">
        </div>

        <div *ngIf="(emailModel.dirty || emailModel.touched) && !isEmailValid" class="alert alert-danger mt-2">
          E-mail nemá správný formát
        </div>
      </div>

      <div class="mb-3">
        <div class="input-group">
          <span class="input-group-prepend"><span class="input-group-text border-right-0"><i class="fa fa-lock"></i></span></span>
          <input
            [(ngModel)]="password"
            required
            #passwordField="ngModel"
            name="password"
            type="password"
            class="form-control"
            placeholder="Heslo">
        </div>

        <div *ngIf="passwordField.invalid && (passwordField.dirty || passwordField.touched)"
             class="alert alert-danger mt-2">
          Heslo je povinné
        </div>
      </div>

      <div class="text-center mt-4">
        <button class="btn btn-primary d-flex align-items-center m-auto" [disabled]="!loginForm.form.valid || authState.loginStatus.isWorking">
          <ng-container *ngIf="!authState.loginStatus.isWorking">Přihlásit se</ng-container>
          <ng-container *ngIf="authState.loginStatus.isWorking">
            <span class="spinner-border spinner-border-sm"></span> &nbsp; Načítám...
          </ng-container>
        </button>
      </div>

    </form>
  </div>
</div>
