import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {

  public isSimple = true;
  public error: any;
  public message: string;
  public internalErrorOccurred = false;
  public pageNotFound = false;
  public url: string;

  @Input() set data(errorData: HttpErrorResponse | Error) {
    if (!errorData) {
      this.error = null;
      return;
    }

    if (typeof errorData === 'object' && errorData instanceof HttpErrorResponse) {
      // Url not found
      if (errorData.status && errorData.status === 404) {
        this.url = errorData.url;
        this.pageNotFound = true;
        return;
      }

      // Internal errors
      if (errorData.status && errorData.status === 500) {
        this.internalErrorOccurred = true;
        return;
      }

      // Handle known codes
      if (errorData.error?.code && this.knownCodes[errorData.error?.code]) {
        this.message = this.knownCodes[errorData.error?.code].title;
        this.error = this.knownCodes[errorData.error?.code].message;
        this.isSimple = false;
        return;
      }

      // Validation errors and weird states
      if (errorData.error?.code && errorData.error?.message && !errorData.error?.errors) {
        this.error = errorData.error;
      } else if (errorData.error && errorData.error.errors) {
        this.isSimple = false;
        this.error = errorData.error.errors.map(e => {
          if (typeof e === 'string') {
            return e;
          } else {
            return Object.values(e.constrains).map(i => ' - ' + i).join('<br>');
          }
        }).join('<br>');

        this.message = errorData.error.message;
      }
    } else if (typeof errorData === 'object' && errorData instanceof Error) {
      this.error = errorData;
      this.isSimple = true;
    } else {
      console.warn('Error handler not supported', errorData);
    }
  }

  public knownCodes = {
    HOUR_ALREADY_ALLOCATED: {
      title: 'Nelze uložit událost',
      message: 'V této místnosti v tuto dobu již probíhá jiná událost. Zvolte prosím jiný čas nebo délku procedury.',
    },
  };

  constructor() {
  }

  ngOnInit() {
  }

}
