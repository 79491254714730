import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { User } from './entities';

export const login = createAction('[AUTH] Login', props<{ email: string; password: string }>());
export const loginSuccessful = createAction('[AUTH] Login successful', props<{bearer: string}>());
export const loginError = createAction('[AUTH] Login error', props<{errorResponse: HttpErrorResponse}>());
export const resetLoginError = createAction('[AUTH] Reset login error');
export const logout = createAction('[AUTH] Logout');
export const goToLogin = createAction('[AUTH] Go to login');
export const setProfile = createAction('[AUTH] Set profile', props<{user: User}>());
