import { Action, createReducer } from '@ngrx/store';

import { ApplicationState } from './application.state';

export const initialState: ApplicationState = {
  showOrganisationPicker: false,
  title: 'Wellfyt',
};

const applicationReducer = createReducer(
  initialState,
);

export function reducer(state: ApplicationState | undefined, action: Action) {
  return applicationReducer(state, action);
}
