import { Action as StoreAction, createReducer, on } from '@ngrx/store';

import * as authActions from './auth.actions';
import { AuthState } from './auth.state';
import { Action } from '../core/utils/action';

export const initialState: AuthState = {
  loginStatus: new Action(true),
  user: null,
};

const authReducer = createReducer<AuthState>(
  initialState,
  on(authActions.login, state => ({ ...state, loginStatus: state.loginStatus.start() })),
  on(authActions.loginError, (state, { errorResponse }) => ({ ...state, loginStatus: state.loginStatus.setError(errorResponse) })),
  on(authActions.loginSuccessful, (state) => ({ ...state, loginStatus: state.loginStatus.setSuccess() })),
  on(authActions.resetLoginError, (state) => ({ ...state, loginStatus: state.loginStatus.setInitial() })),
  on(authActions.setProfile, (state, action) => ({ ...state, user: action.user })),
);

export function reducer(state: AuthState | undefined, action: StoreAction) {
  return authReducer(state, action);
}
