import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as fromActions from './auth.actions';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  @Effect()
  login$ = this.actions$.pipe(
    ofType(fromActions.login),
    switchMap(({ email, password }) => {
      return this.authService.login({ email, password })
        .pipe(
          map(response => fromActions.loginSuccessful(response)),
          catchError(errorResponse => of(fromActions.loginError({errorResponse}))),
        );
    }),
  );

  @Effect({ dispatch: false })
  loginSuccessful$ = this.actions$.pipe(
    ofType(fromActions.loginSuccessful),
    map(() => this.router.navigateByUrl('/')),
  );

  @Effect({ dispatch: false })
  goToLogin$ = this.actions$.pipe(
    ofType(fromActions.goToLogin),
    tap(() => this.router.navigateByUrl('/auth/login')),
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(fromActions.logout),
    switchMap(() => this.authService.logout().pipe(
      map(() => this.router.navigateByUrl('/auth/login')),
      catchError(() => {
        alert('Cannot logout');
        return of(null);
      }),
    )),
  );

}
