import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { StringsService } from '../../../core/services/strings.service';
import { AuthService } from '../../services/auth.service';
import { AppState } from '../../../app.state';
import * as fromActions from '../../auth.actions';
import { toggleVisibility } from '../index';
import { getAuthState } from '../../index';
import { AuthState } from '../../auth.state';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    toggleVisibility,
  ],
})
export class LoginComponent implements OnInit, OnDestroy {

  public isEmailValid = true;
  public email: string;
  public password: string;
  public resetPassword = false;
  public errorMessage: string;
  public authState: AuthState;

  private storeSubscription: Subscription;

  @ViewChild('emailField', { static: true }) emailField: ElementRef;
  @ViewChild('loginForm', { static: true }) loginForm: NgForm;

  constructor(
    private store: Store<AppState>,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private stringsService: StringsService) {
  }

  public ngOnInit() {
    this.store.dispatch(fromActions.resetLoginError());
    this.storeSubscription = this.store.select(getAuthState)
      .subscribe(authState => {
        this.authState = authState;
        const errorResponse = authState.loginStatus.error;
        this.errorMessage = errorResponse ? (errorResponse?.error?.code || 'UNKNOWN_ERROR') : null;
      });

    this.emailField.nativeElement.focus();
    this.resetPassword = this.activeRoute.snapshot.queryParams.reset || false;
    this.email = this.activeRoute.snapshot.queryParams.email;
  }

  public ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  public onSubmit() {
    if (!this.stringsService.validateEmail(this.loginForm.value.email)) {
      this.isEmailValid = false;
      return false;
    }

    this.isEmailValid = true;
    this.store.dispatch(fromActions.login(this.loginForm.value));
  }

}
