import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppState } from '../../app.state';
import { goToLogin } from '../auth.actions';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public store: Store<AppState>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.apiUrl) === 0) {
      request = request.clone({
        withCredentials: true,
      });
    }

    return next
      .handle(request)
      .pipe(
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.error && err.error.code && err.error.code === 'AUTHORIZATION_REQUIRED') {
              this.store.dispatch(goToLogin());
            }
          }

          throw err;
        }),
      );
  }

}
