import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorComponent } from './components/error-handler/error.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { NgbDatePipe } from './pipes/ngb-date.pipe';
import { SwitchComponent } from './components/switch/switch.component';
import { UcfirstPipe } from './pipes/ucfirst.pipe';
import { CounterComponent } from './components/counter/counter.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';

@NgModule({
  declarations: [
    ErrorComponent,
    ConfirmDialogComponent,
    NgbDatePipe,
    SwitchComponent,
    UcfirstPipe,
    CounterComponent,
    MonthPickerComponent,
  ],
  exports: [
    ErrorComponent,
    NgbDatePipe,
    SwitchComponent,
    UcfirstPipe,
    CounterComponent,
    MonthPickerComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class SharedModule {
}
